import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { Collapse } from "@material-ui/core";

class FooterMenu extends Component {
  constructor(props) {
    super();
    this.state = {
      visible: false,
    };

    this.handleClick = this.handleClick.bind(this);
  }

  handleClick() {
    this.setState({ visible: !this.state.visible });
  }

  render() {
    return (
      <nav className="menu-primary">
        <ul className="nav">
          <li
            className={
              "nav-item " +
              (this.props.location.pathname === window.location.pathname &&
              this.props.location.pathname === process.env.PUBLIC_URL + "/"
                ? "current-nav-item"
                : "")
            }
          >
            <Link to={process.env.PUBLIC_URL + "/"} title="Home">
              Home
            </Link>
          </li>

          <li
            className={
              "nav-item " +
              (window.location.pathname.includes("/services") ||
              window.location.pathname.includes("/service-inside")
                ? "current-nav-item"
                : "")
            }
          >
            <Link to={process.env.PUBLIC_URL + "/services"} title="Services">
              Prodotti
            </Link>
          </li>

          <li
            className={
              "nav-item " +
              (window.location.pathname.includes("/about-us")
                ? "current-nav-item"
                : "")
            }
          >
            <Link to={process.env.PUBLIC_URL + "/about-us"} title="Chi siamo">
              Chi siamo
            </Link>
          </li>

          <li
            className={
              "nav-item " +
              (window.location.pathname.includes("/why-us")
                ? "current-nav-item"
                : "")
            }
          >
            <Link to={process.env.PUBLIC_URL + "/why-us"} title="Perche noi">
              Perche noi
            </Link>
          </li>

          <li
            className={
              "nav-item " +
              (window.location.pathname.includes("/gallery")
                ? "current-nav-item"
                : "")
            }
            id="nav-gallery"
          >
            <Link to={"/gallery"} title="Showroom" href="#gallery">
              Showroom
            </Link>
          </li>

          <li
            className={
              "nav-item " +
              (window.location.pathname.includes("/contacts")
                ? "current-nav-item"
                : "")
            }
          >
            <Link to={process.env.PUBLIC_URL + "/contacts"} title="Contatti">
              Contatti
            </Link>
          </li>
        </ul>
      </nav>
    );
  }
}

export default withRouter(FooterMenu);
