import React, { Component } from "react";
import { Collapse } from "@material-ui/core";
import { Link } from "react-router-dom";

class ModalMenuPrimary extends Component {
  constructor(props) {
    super();
    this.state = {
      open: false,
    };
  }

  handleClick = () => {
    this.setState({ open: !this.state.open });
  };

  render() {
    return (
      <nav className="menu-primary">
        <ul className="list-unstyled">
          <li className="nav-item ">
            <Link to={process.env.PUBLIC_URL + "/"} title="Home">
              Home
            </Link>
          </li>

          <li
            className={
              "nav-item " +
              (window.location.pathname.includes("/services") ||
              window.location.pathname.includes("/service-inside")
                ? "current-nav-item"
                : "")
            }
          >
            <Link to={process.env.PUBLIC_URL + "/services"} title="Services">
              Prodotti
            </Link>
          </li>

          <li
            className={
              "nav-item " +
              (window.location.pathname.includes("/about-us")
                ? "current-nav-item"
                : "")
            }
          >
            <Link to={process.env.PUBLIC_URL + "/about-us"} title="About us">
              Chi siamo
            </Link>
          </li>

          <li
            className={
              "nav-item " +
              (window.location.pathname.includes("/why-us")
                ? "current-nav-item"
                : "")
            }
          >
            <Link to={process.env.PUBLIC_URL + "/why-us"} title="Why us">
              Perche scegliere noi
            </Link>
          </li>

          <li className="nav-item" id="nav-gallery">
            <Link
              to="gallery"
              title="Gallery"
              onClick={(e) => this.handleClick(e)}
              href="#gallery"
            >
              Showroom
            </Link>
          </li>

          <li
            className={
              "nav-item " +
              (window.location.pathname.includes("/clients")
                ? "current-nav-item"
                : "")
            }
          >
            <Link to={process.env.PUBLIC_URL + "/clients"} title="Clients">
              Clienti
            </Link>
          </li>

          <li
            className={
              "nav-item " +
              (window.location.pathname.includes("/contacts")
                ? "current-nav-item"
                : "")
            }
          >
            <Link to={process.env.PUBLIC_URL + "/contacts"} title="Contacts">
              Contatti
            </Link>
          </li>
        </ul>
      </nav>
    );
  }
}

export default ModalMenuPrimary;
