import React, { Component } from "react";

import ServicesContentData from "../../data/services/servicesContentItems.json";

class ServicesContent extends Component {
  componentDidMount() {}

  render() {
    return (
      <div className="service-items container-fluid ">
        <div className="row">
          {ServicesContentData &&
            ServicesContentData.map((item, key) => {
              return (
                <div key={key} className="col-12 col-sm-6 pt-3 col-md-3">
                  <a
                    title={item.title}
                    className="service-item"
                    href={item.link}
                  >
                    <div className="service-content">
                      <div className="service-content-sec">
                        <div className="text">
                          <h5 className="service-title">{item.title}</h5>
                          <p>{item.description}</p>
                        </div>

                        <div className="button no-space">
                          <p className="p-small bold transform-scale-h">
                            Read more
                            <i className="fas fas-space-l fa-long-arrow-alt-right align-middle"></i>
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="img object-fit">
                      <div className="object-fit-cover">
                        <img src={item.imgSrc} alt={item.title} />
                      </div>
                    </div>

                    <div className="img-bg-color"></div>
                  </a>
                </div>
              );
            })}
        </div>
      </div>
    );
  }
}

export default ServicesContent;
