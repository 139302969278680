import React from "react";

const ServiceInsideWidget = ({ details }) => {
  return (
    <div className="card">
      <div className="card-body">
        <h5 className="card-title">Tipologie</h5>

        <div className="card-text">
          <ul>{details && details.map((el) => <li>{el}</li>)}</ul>
          {/* <p>Full Container Load (FCL)</p>
          <p>Reserve Sailing time</p>
          <p>Cargo Insurance</p>
          <p>Worldwide Transport</p>
          <p>Support 24/7</p> */}
        </div>
      </div>

      {/* <div className="card-link">
                <a className="btn btn-secondary btn-lg w-100" href={ process.env.PUBLIC_URL + '/contacts' }>Order plan</a>
            </div> */}
    </div>
  );
};

export default ServiceInsideWidget;
