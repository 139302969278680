import React from "react";

import FooterMenu from "../footer/FooterMenu";

const FooterWidget = () => {
  return (
    <div className="footer-widgets">
      <div className="footer-widget-area d-flex flex-wrap">
        <div className="widget">
          <h5 className="widget-title">Chi siamo</h5>

          <p>
            Prima ancora che nascesse la società infissi D'Amato srls , era una
            ditta individuale...
          </p>
        </div>

        <div className="widget">
          <h5 className="widget-title">Menu</h5>

          <FooterMenu />
        </div>

        <div className="widget">
          <h5 className="widget-title">Contatti</h5>

          <p>Via Principe,1, Padula, Italy</p>

          <p>
            <a href="mailto:info@info.com">infissidamato.a@gmail.com</a>
          </p>

          <p>0975 74703</p>
        </div>
      </div>
    </div>
  );
};

export default FooterWidget;
