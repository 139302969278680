import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Home from "./pages/Home";
import About from "./pages/About";
import Contacts from "./pages/Contacts";
import Gallery from "./pages/Gallery";
import Clients from "./pages/Clients";
import Services from "./pages/Services";
import ServiceInside from "./pages/ServiceInside";
import page404 from "./pages/404";
import WhyUs from "./pages/WhyUs";

function App() {
  return (
    <Router>
      <Switch>
        <Route exact path={"/"} component={Home} />

        <Route exact path={"/about-us"} component={About} />

        <Route exact path={"/contacts"} component={Contacts} />

        <Route exact path={"/gallery"} component={Gallery} />

        <Route exact path={"/services"} component={Services} />

        <Route exact path={"/service-inside/:id"} component={ServiceInside} />

        <Route exact path={"/why-us"} component={WhyUs} />

        <Route exact path={"/clients"} component={Clients} />

        <Route exact component={page404} />
        {/* <Route exact path={"/gallery-inside"} component={GalleryInside} /> */}

        {/* <Route exact path={"/news"} component={News} />

        <Route exact path={"/news-single-page"} component={NewsSinglePage} /> */}

        {/* <Route exact path={"/reviews"} component={Reviews} /> */}

        {/* <Route exact path={"/search-results"} component={SearchResults} /> */}

        {/* <Route exact path={"/ui"} component={UserInterface} /> */}
      </Switch>
    </Router>
  );
}

export default App;
