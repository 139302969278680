import React from "react";

const Copyright = () => {
  return (
    <div className="copyright" style={{ textAlign: "center" }}>
      <p>© 2022 Infissi D'Amato srls</p>
      <p target={"_blank"} href="https://www.aldonoschese.com">
        Aldo Noschese - Professional Web Developer
      </p>
    </div>
  );
};

export default Copyright;
