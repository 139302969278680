import React, { Component } from "react";
import Typed from "react-typed";
import LanguageSelector from "../../components/button/LanguageSelector";

class PageTitleHome extends Component {
  constructor() {
    super();
    this.state = {
      lang: "En",
    };

    this.onChangeValue = this.onChangeValue.bind(this);
  }

  onChangeValue(event) {
    this.setState({
      lang: event.target.title,
    });
  }

  render() {
    return (
      <section
        id="page-title"
        className="block"
        style={{
          backgroundImage: `url( ${require("../../assets/img/bg/heroImage.jpg")} )`,
        }}
      >
        <div className="wrapper h-100">
          <div className="d-flex justify-content-between position-relative h-100">
            <div className="align-self-center">
              <div className="title">
                <h1>
                  Infissi D'Amato <br />
                  Dal 1995 curiamo i tuoi infissi
                </h1>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <span style={{ color: "white", paddingBottom: "0.5rem" }}>
                    Diamo valore alla tua casa
                  </span>
                  <Typed
                    style={{ color: "white" }}
                    strings={[
                      "Aumenta il risparmio energetico.",
                      "Sostituisci i tuoi infissi.",
                      "Sopralluogo e rilievo.",
                      "Assistenza commerciale.",
                    ]}
                    typeSpeed={40}
                    loop
                  />
                </div>
              </div>

              {/* <p className="spacer p-top-lg mb-0">
                Con esperienza e passione,da sempre.
              </p> */}
            </div>

            {/* <div className="lang-position">
                            <nav className="languages">
                                <ul className="nav" onClick={ this.onChangeValue }>
                                    <LanguageSelector title="En" className={ this.state.lang === 'En' ? 'current-lang' : '' } />
                                    <LanguageSelector title="Ru" className={ this.state.lang === 'Ru' ? 'current-lang' : '' } />
                                    <LanguageSelector title="Lt" className={ this.state.lang === 'Lt' ? 'current-lang' : '' } />
                                </ul>
                            </nav>
                        </div> */}
          </div>
        </div>

        <div className="page-title-bg-color"></div>
      </section>
    );
  }
}

export default PageTitleHome;
