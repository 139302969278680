import React, { Fragment, useEffect } from "react";
import MetaTags from "react-meta-tags";

import Loading from "../blocks/loading/Loading";
import Header from "../blocks/header/Header";
import Footer from "../blocks/footer/Footer";

import PageTitleCommon from "../blocks/page-title/PageTitleCommon";
import ServiceInsideSwiper from "../blocks/service-inside/ServiceInsideSwiper";
import ServiceInsideWidget from "../blocks/service-inside/ServiceInsideWidget";
import ServicesData from "../data/services/servicesContentItems.json";
import { useParams } from "react-router-dom";

const ServiceInside = () => {
  const [product, setProduct] = React.useState({
    pageTitle: "",
    images: [],
  });
  const params = useParams();

  const getProductFieldByParams = (params) => {
    if (params.id && ServicesData) {
      const product = ServicesData.find(({ id }) => parseInt(id) == params.id);
      setProduct((prev) => ({
        ...prev,
        description: product.descriptionLong,
        products: product.products,
        details: product.details,
        img: product.imgSrc,
        productId: product.id,
        metaTitle: product.title,
        pageTitle: product.title,
        images: product.images,
      }));
    }
  };

  useEffect(() => {
    document.body.classList.add("single");
    document.body.classList.add("single-adveits_service");
    return () => {
      document.body.classList.remove("single");
      document.body.classList.remove("single-adveits_service");
    };
  }, []);

  useEffect(() => {
    getProductFieldByParams(params);
  }, [params]);
  console.log(product, "ppp");
  return (
    <Fragment>
      <MetaTags>
        <meta charSet="UTF-8" />
        <title>{product.metaTitle} | Infissi D'Amato</title>

        <meta httpEquiv="x-ua-compatible" content="ie=edge" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="description" content="" />
        <meta name="keywords" content="" />
        <meta name="robots" content="index, follow, noodp" />
        <meta name="googlebot" content="index, follow" />
        <meta name="google" content="notranslate" />
        <meta name="format-detection" content="telephone=no" />
      </MetaTags>

      <Loading />

      <Header logoColor="dark" />

      <main id="main" className="site-main">
        <PageTitleCommon
          productId={product && product.productId}
          title={product && product.pageTitle}
        />

        <section id="page-content" className="spacer p-top-xl">
          <div className="wrapper">
            <div className="content">
              <div id="single">
                <div className="row gutter-width-md single-content">
                  <div className="col-xl-9 col-lg-9 col-md-12 col-sm-12">
                    <ServiceInsideSwiper id={params.id} />

                    <div className="description style clearfix spacer m-top-lg">
                      {/* <h5>Tipologie</h5>
                      <ul>
                        {product &&
                          product.products &&
                          product.products.map((p) => {
                            return <li>{p}</li>;
                          })}
                      </ul>
                      <h5>
                        {product && product.description
                          ? product.description
                          : ""}
                      </h5> */}
                    </div>
                  </div>

                  <div className="col-xl-3 col-lg-3 col-md-12 col-sm-12">
                    <aside id="aside" className="widget-area style-default">
                      <div id="service" className="widget_service">
                        <ServiceInsideWidget details={product.details} />
                      </div>
                    </aside>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>

      <Footer />
    </Fragment>
  );
};

export default ServiceInside;
