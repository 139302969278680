import React, { useState } from "react";
import { Link } from "react-scroll";
import { Collapse } from "@material-ui/core";

const ModalMenuHome = () => {
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen((prev) => !prev);
  };

  const modalOff = () => {
    let e = document.getElementById("close-modal");

    if (e) {
      e.click();
    }
  };

  return (
    <nav className="menu-primary">
      <ul className="list-unstyled">
        <li className="nav-item">
          <Link
            to="products"
            spy={true}
            smooth={true}
            duration={300}
            onClick={modalOff}
            title="Services"
            href="#"
          >
            Prodotti
          </Link>
        </li>

        <li className="nav-item">
          <Link
            to="about-us"
            spy={true}
            smooth={true}
            duration={300}
            onClick={modalOff}
            title="About us"
            href="#"
          >
            Chi Siamo
          </Link>
        </li>

        <li className="nav-item" id="nav-why-us">
          <Link
            to="why-us"
            title="Why us"
            href="#"
            spy={true}
            smooth={true}
            duration={300}
            onClick={modalOff}
          >
            Perche scegliere noi
          </Link>
        </li>
        <li className="nav-item" id="nav-gallery">
          <Link
            to="gallery"
            title="Gallery"
            spy={true}
            smooth={true}
            duration={300}
            onClick={modalOff}
            href="#"
          >
            Showroom
          </Link>
        </li>

        {/* <li className="nav-item">
          <Link
            to="clients"
            spy={true}
            smooth={true}
            duration={300}
            onClick={modalOff}
            title="Clients"
            href="#"
          >
            Clienti
          </Link>
        </li> */}

        <li className="nav-item">
          <Link
            to="contacts"
            spy={true}
            smooth={true}
            duration={300}
            onClick={modalOff}
            title="Contacts"
            href="#"
          >
            Contatti
          </Link>
        </li>
      </ul>
    </nav>
  );
};

export default ModalMenuHome;
